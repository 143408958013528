import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Salto from './screens/salto'
import Fogao from './screens/fogao'
import Geladeira from './screens/geladeira'
import Mesa from './screens/Mesa'
import Cadeira from './screens/cadeira'

function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link className="btnMenu" to="/">Salto</Link>
            </li>
            <li>
              <Link className="btnMenu" to="/fogao">Fogao</Link>
            </li>
            <li>
              <Link className="btnMenu" to="/geladeira">Geladeira</Link>
            </li>
            <li>
              <Link className="btnMenu" to="/mesa">Mesa</Link>
            </li>
            <li>
              <Link className="btnMenu" to="/cadeira">Cadeira</Link>
            </li>
          </ul>
        </nav>

      
      <Switch>
        <Route  exact path="/">
          <Salto />
        </Route>
        <Route path="/fogao">
          <Fogao />
        </Route>
        <Route path="/geladeira">
          <Geladeira />
        </Route>
        <Route path="/mesa">
          <Mesa />
        </Route>
        <Route path="/cadeira">
          <Cadeira />
        </Route>
      </Switch>
      </div>
    </Router>
  );
}

export default App;
