import React from 'react';
import '@google/model-viewer';
import '../App.css'

import model3D_glb from '../assets/cadeira.glb'
import model3D_usdz from '../assets/cadeira.usdz'

function Cadeira() {
  return (
    <div className="App">
      <model-viewer
        src={model3D_glb}
        ios-src={model3D_usdz}
        autoplay
        ar
        ar-modes="webxr scene-viewer quick-look"
        camera-controls
        alt="A 3D model of an build make by Dilis"
        auto-rotate
        data-js-focus-visible
      >
      </model-viewer>
    </div>
  );
}

export default Cadeira;